// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html {
  scroll-behavior: smooth;
  overflow-x: hidden;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Raleway', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #1D1D1D;
  font-variant-numeric: lining-nums;
  font-feature-settings: 'lnum';
  -moz-font-feature-settings: 'lnum=1';
  -ms-font-feature-settings: 'lnum';
  -webkit-font-feature-settings: 'lnum';
  -o-font-feature-settings: 'lnum';
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
  font-variant-numeric: lining-nums;
  font-feature-settings: 'lnum';
  -moz-font-feature-settings: 'lnum=1';
  -ms-font-feature-settings: 'lnum';
  -webkit-font-feature-settings: 'lnum';
  -o-font-feature-settings: 'lnum';
}
body::-webkit-scrollbar {
  width: 5px;
}
body::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 6px;
}
body::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,kBAAkB;AACpB;AACA;EACE,SAAS;EACT;;cAEY;EACZ,mCAAmC;EACnC,kCAAkC;EAClC,mBAAmB;EACnB,iCAAiC;EACjC,6BAA6B;EAC7B,oCAAoC;EACpC,iCAAiC;EACjC,qCAAqC;EACrC,gCAAgC;AAClC;AACA;EACE;aACW;EACX,iCAAiC;EACjC,6BAA6B;EAC7B,oCAAoC;EACpC,iCAAiC;EACjC,qCAAqC;EACrC,gCAAgC;AAClC;AACA;EACE,UAAU;AACZ;AACA;EACE,sBAAsB;EACtB,kBAAkB;AACpB;AACA;EACE,sBAAsB;AACxB","sourcesContent":["html {\n  scroll-behavior: smooth;\n  overflow-x: hidden;\n}\nbody {\n  margin: 0;\n  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Raleway', 'Oxygen',\n    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n    sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n  background: #1D1D1D;\n  font-variant-numeric: lining-nums;\n  font-feature-settings: 'lnum';\n  -moz-font-feature-settings: 'lnum=1';\n  -ms-font-feature-settings: 'lnum';\n  -webkit-font-feature-settings: 'lnum';\n  -o-font-feature-settings: 'lnum';\n}\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n    monospace;\n  font-variant-numeric: lining-nums;\n  font-feature-settings: 'lnum';\n  -moz-font-feature-settings: 'lnum=1';\n  -ms-font-feature-settings: 'lnum';\n  -webkit-font-feature-settings: 'lnum';\n  -o-font-feature-settings: 'lnum';\n}\nbody::-webkit-scrollbar {\n  width: 5px;\n}\nbody::-webkit-scrollbar-thumb {\n  background-color: #888;\n  border-radius: 6px;\n}\nbody::-webkit-scrollbar-thumb:hover {\n  background-color: #555;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
