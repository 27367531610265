import axios from "axios";

// Function to set headers with authorization token
const setAuthHeader = (token) => {
    if (token) {
        axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    } else {
        delete axios.defaults.headers.common["Authorization"];
    }
};

// Log in
export const signIn = async (credentials) => {
    try {
        const response = await axios.post(process.env.REACT_APP_API_URL + `/api/v1/auth/signin`, credentials);
        const { accessToken, userData } = response.data;
        setAuthHeader(accessToken);

        return { accessToken, userData };
    } catch (error) {
        console.error("Error when executing a POST request:", error);
        throw error;
    }
};
