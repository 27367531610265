import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Main from './components/main/Main';
import About from './components/about/About';
import Advantages from './components/advantages/Advantages';
import Search from './components/search/Search';
import Check from './components/check/Check';
import Pricing from './components/pricing/Pricing';
import Footer from './components/footer/Footer';
import Privacy from "./components/policy/Privacy";

function App() {
    return (
        <div>
            <Main />
            <About />
            <Advantages />
            <Search />
            <Check />
            <Pricing />
            <Footer />
        </div>
    );
}

const RoutesApp = () => {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<App />} />
                <Route path="/privacy-policy" element={<Privacy />} />
            </Routes>
        </Router>
    );
};

export default RoutesApp;
