import React from 'react';
import './styles/CustomCheckbox.css';

function CustomCheckboxLogin({ isChecked, onChange }) {

    return (
        <label className="custom-checkbox">
            <input
                type="checkbox"
                checked={isChecked}
                onChange={onChange}
                //required
            />
            <span className="checkmark"></span>
            <label htmlFor="agreementCheckbox">
                Remember Me
            </label>
        </label>
    );
}

export default CustomCheckboxLogin;
