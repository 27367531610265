import React from 'react';
import {useTranslation} from 'react-i18next';
import Presentation from '../../assets/image/images/presintations.svg';
import Advantage from '../../assets/image/images/advantages.png';
import '../advantages/styles/styles.css';
import '../advantages/styles/@styles.css';

function Advantages() {
    const { t } = useTranslation();
    return(
        <div className='advantages' id='advantages'>
            <div className='advantages-container'>
                <img src={Advantage} alt=''/>
                <div className='advantages-container__content'>
                    <div className='advantages-container__content-header'>
                        <p>{t('Advantages')}</p>
                    </div>
                    <div className='advantages-container__content-body'>
                        <p><span className='span'>{t('Our tool has a number of ')}<span>{t('advantages')}</span>{t(' that will')}</span>{t(' help you ')}<span>{t('work efficiently.')}</span></p>
                    </div>
                </div>
            </div>
            <div className='advantages-presintations'>
                <div className='advantages-presintations__container'>
                    <div className='advantages-presintations__container-info'>
                        <div className='advantages-presintations__container-info__header'>
                            <p>{t('Generate ')}<span>{t('presentations')}</span></p>
                        </div>
                        <div className='advantages-presintations__container-info__body'>
                            <p>
                                <span>{t('Download information about the')}</span>{t(' object in the form of presentations and send it to your clients.')}
                            </p>
                        </div>
                    </div>
                    <div className='advantages-presintations__container-demo'>
                        <div className='advantages-presintations__container-demo__header'>
                            <div className='advantages-presintations__container-demo__header-container'>
                                <div className='advantages-presintations__container-demo__header-container__content'>
                                    <p>Presentations</p>
                                </div>
                                <div className='advantages-presintations__container-demo__header-container__btn'>
                                    <button>Generate</button>
                                </div>
                            </div>
                        </div>
                        <div className='advantages-presintations__container-demo__body'>
                            <img src={Presentation} alt='Presintations'/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Advantages;
