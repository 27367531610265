import React, {useEffect, useState} from 'react';
import ReactDOM from 'react-dom';
import {useTranslation} from 'react-i18next';
import Signin from '../signin/Signin';
import Instagram from '../../assets/image/icons/instagram.svg';
import Facebook from '../../assets/image/icons/facebook.svg';
import Twitter from '../../assets/image/icons/twitter.svg';
import Telegram from '../../assets/image/icons/telegram.svg';
import Whatsapp from '../../assets/image/icons/whatsapp.svg';
import Connect from '../../assets/image/images/connect.svg';
import './styles/styles.css';
import './styles/@styles.css';
import {Link} from "react-router-dom";

function Footer() {
    const [isSignupOpen, setIsSignupOpen] = useState(false);
    const { t } = useTranslation();

    const toggleSignup = () => {
        const newSignupState = !isSignupOpen;
        setIsSignupOpen(newSignupState);
        document.body.style.overflow = newSignupState ? 'hidden' : 'auto';
    };

    useEffect(() => {
        if (isSignupOpen) {
            const signinContainer = document.createElement('div');
            signinContainer.id = 'signin-container';
            document.body.insertBefore(signinContainer, document.body.firstChild);

            ReactDOM.render(<Signin onClose={toggleSignup} />, signinContainer);
        }

        return () => {
            const signinContainer = document.getElementById('signin-container');
            if (signinContainer) {
                document.body.removeChild(signinContainer);
            }
        };
    }, [isSignupOpen]);

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const section = params.get('section');

        if (section === 'footer') {
            const aboutElement = document.getElementById('footer');
            if (aboutElement) {
                aboutElement.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, []);

    return(
        <>
            <div className='footer' id='footer'>
                <div className='footer-logo'>
                    {/*<img src={Logo} alt=''/>*/}
                    <img src={Connect} alt=''/>
                </div>
                <div className='footer-container'>
                    <div className='footer-container__content'>
                        <div className='footer-container__content-link'>
                            <div className='footer-container__content-link__links'>
                                <a href='#about'>{t('About')}</a>
                                <a href='#advantages'>{t('Advantages')}</a>
                                <a href='#pricing'>{t('Pricing')}</a>
                            </div>
                            <div className='footer-container__content-link__networks'>
                                <a href='/'><img src={Instagram} alt='Instagram'/></a>
                                <a href='/'><img src={Facebook} alt='Facebook'/></a>
                                <a href='/'><img src={Twitter} alt='Twitter'/></a>
                                <a href='/'><img src={Telegram} alt='Telegram'/></a>
                                <a href='/'><img src={Whatsapp} alt='Whatsapp'/></a>
                            </div>
                        </div>
                        <div className='footer-container__content-btn'>
                            <div className='footer-container__content-btn__logo'>
                                <div className='footer-container__content-btn__logo-text'>
                                    <p>connect.</p>
                                </div>
                            </div>
                            <div className='footer-container__content-btn'>
                                <div className='footer-container__content-btn__button' onClick={toggleSignup}>
                                    <button>{t('Get started')}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='footer-copyrite'>
                    <Link to='/privacy-policy' target = '_blank' rel = ''>{t('Privacy Policy')}</Link>
                    <span>{t('© 2023 all rights reserved')}</span>
                </div>
            </div>
            {isSignupOpen && <Signin onClose={toggleSignup}/>}
        </>
    )
}

export default Footer;
