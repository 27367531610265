import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import '../about/styles/styles.css';
import '../about/styles/@styles.css';

function About() {
    const { t } = useTranslation();

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const section = params.get('section');

        if (section === 'about') {
            const aboutElement = document.getElementById('about');
            if (aboutElement) {
                aboutElement.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, []);

    return (
        <div className='about' id='about'>
            <div className='about-container'>
                <div className='about-container__header'>
                    <p>{t('About')}</p>
                </div>
                <div className='about-container__body'>
                    <p>
                        <span className='span'>{t('We have created a ')}<span>{t('unique platform')}</span>{t(' that')}</span>{t(' allows brokers to ')}<span>{t('publish, find')}</span>{t(' and ')}<span>{t('share')}</span>{t(' real properties in UAE.')}
                    </p>
                </div>
            </div>
        </div>
    );
}

export default About;
