import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { signIn } from '../../action/auth_api';
import Facebook from '../../assets/image/icons/signup/facebook.svg';
import Google from '../../assets/image/icons/signup/google.svg';
import EmailInput from '../../assets/image/icons/email.svg';
import PasswordInput from '../../assets/image/icons/key.svg';
import Cancel from '../../assets/image/icons/signup/cancel.svg';
import CustomCheckboxLogin from "../chekbox/CustomChekboxLogin";
import './styles/styles.css';
import './styles/@styles.css';

const validationSchema = yup.object({
    email: yup.string().required('Enter your email address').email('Please enter a valid email address'),
    password: yup.string().required('Enter your password'),
});

function Signin({ onClose }) {
    const [error, setError] = useState(null);
    const [rememberMe, setRememberMe] = useState(false);

    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            try {
                const response = await signIn(values);
                console.log('Login successful:', response);

                const token = response.accessToken;
                document.cookie = `token=${token}; max-age=${rememberMe ? 604800 : 86400}; path=/; domain=.connect.tw`;
                document.cookie = `token=${token}; Max-Age=${rememberMe ? 604800 : 86400}; path=/; domain=192.168.0.222`;
                document.cookie = `token=${token}; Max-Age=${rememberMe ? 604800 : 86400}; path=/; domain=185.233.187.80`;

                if (token) {
                    window.location.href = process.env.REACT_APP_FRONTEND_URL;
                    // window.location.href = `http://192.168.0.222:3000`;
                    console.log('cookie', document.cookie);
                } else {
                    console.error('Token not found in server response');
                }
            } catch (error) {
                console.error('Error during login:', error);
                setError('Incorrect login or password');
            }
        },
    });
    const handleRememberMeChange = () => {
        setRememberMe(!rememberMe);
    };

    return (
        <div className='login'>
            <div className='login-container'>
                <div className='login-container__content'>
                    <div className='login-container__content-controls'>
                        <div className='login-container__content-controls__back'>
                            {/*<button onClick={handleBack}><img src={Back} alt='Back'/>Back</button>*/}
                        </div>
                        <div className='login-container__content-controls__cancel' onClick={onClose}>
                            <button><img src={Cancel} alt='Cancel' /></button>
                        </div>
                    </div>
                    <div className='login-container__content-container'>
                        <div className='login-container__content-container__network'>
                            <div className='login-container__content-container__network-facebook'>
                                <button><img src={Facebook} alt='Facebook' />Continue with Facebook</button>
                            </div>
                            <div className='login-container__content-container__network-google'>
                                <button><img src={Google} alt='Google' />Continue with Google</button>
                            </div>
                        </div>
                        <div className='login-container__content-container__or'>
                            <p>OR</p>
                        </div>
                        <div className='login-container__content-container__table'>
                            <form className='login-container__content-container__table-form' onSubmit={formik.handleSubmit}>
                                <div className='login-container__content-container__table-form__group'>
                                    <img src={EmailInput} alt='' />
                                    {formik.values.email.length > 0 && <span>Email</span>}
                                    <input
                                        type='email'
                                        id='email'
                                        name='email'
                                        placeholder='Email'
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.email}
                                    />
                                    {formik.touched.email && formik.errors.email && (
                                        <div className='error-message'>{formik.errors.email}</div>
                                    )}
                                </div>
                                <div className='login-container__content-container__table-form__group'>
                                    <img src={PasswordInput} alt='' />
                                    {formik.values.password.length > 0 && <span>Password</span>}
                                    <input
                                        type='password'
                                        id='password'
                                        name='password'
                                        placeholder='Password'
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.password}
                                    />
                                    {formik.touched.password && formik.errors.password && (
                                        <div className='error-message'>{formik.errors.password}</div>
                                    )}
                                </div>
                                <div className='login-container__content-container__table-form__group'>
                                    <button
                                        type='submit'
                                        className='continue-button-active'
                                    >
                                        Log in
                                    </button>
                                    {error && (
                                        <div className='login-container__content-container__table-form__group'>
                                            <p className='error-message'>{error}</p>
                                        </div>
                                    )}
                                </div>
                            </form>
                            <div className='login-container__content-container__table-footer'>
                                <div className='login-container__content-container__table-footer__chekbox'>
                                    <CustomCheckboxLogin
                                        id="agreementCheckbox"
                                        onChange={handleRememberMeChange}
                                    />
                                </div>
                                <div className='login-container__content-container__table-footer__forgot'>
                                    <p>Forgot Password?</p>
                                </div>
                            </div>
                            <div className='login-container__content-container__table-create'>
                                <p>Create new account</p>
                                <button type='button'>Sign up</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Signin;
