import React from 'react';
import {useTranslation} from 'react-i18next';
import Searching from '../../assets/image/images/search.png';
import Search_Moblie from '../../assets/image/images/search_mobile.png';
import './styles/styles.css';
import './styles/@styles.css';

function Search() {
    const { t } = useTranslation();
    return(
        <div className='search'>
            <div className='search-container'>
                <div className='search-container__img'>
                    <img src={Searching} alt='Search' className='desktop'/>
                    <img src={Search_Moblie} alt='Search' className='mobile'/>
                </div>
                <div className='search-container__content'>
                    <div className='search-container__content-header'>
                        <p>{t('Save ')}<span>{t('search')}</span></p>
                    </div>
                    <div className='search-container__content-body'>
                        <p><span>{t('No more wasting time re-')}</span>
                            {t('adjusting filters. Our platform allows you to save your search parameters so you can quickly get up-to-date results.')}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Search;
