import React from 'react';
import {useTranslation} from 'react-i18next';
import Checking from "../../assets/image/images/check.png";
import CheckingMob from "../../assets/image/images/checkingMob.svg";
import './styles/styles.css';
import './styles/@styles.css';

function Check() {
    const { t } = useTranslation();
    return(
        <div className='check'>
            <div className='check-container'>
                <div className='check-container__content'>
                    <div className='check-container__content-header'>
                        <p>{t('Check ')}<span>{t('licence')}</span></p>
                    </div>
                    <div className='check-container__content-body'>
                        <p><span>{t('We value safety and reliability. ')}</span>
                            {t('Before registering each real estate and broker\'s license, we conduct a thorough background check to ensure they are accurate.')}
                        </p>
                    </div>
                </div>
                <div className='check-container__img desktop'>
                    <img src={Checking} alt='Checking'/>
                </div>
                <div className='check-container__img mobile'>
                    <img src={CheckingMob} alt='Checking'/>
                </div>
            </div>
        </div>
    )
}

export default Check;
