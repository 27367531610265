import React from 'react';
import ReactDOM from 'react-dom/client';
import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import './index.css';
import reportWebVitals from './reportWebVitals';

import enTranslation from './languages/en.json';
import arTranslation from './languages/ar.json';
import ruTranslation from './languages/ru.json';
import RoutesApp from "./App";
import '../src/assets/fonts/font.css';

i18n
    .use(initReactI18next)
    .init({
        resources: {
            en: {
                translation: enTranslation,
            },
            ar: {
                translation: arTranslation,
            },
            ru: {
                translation: ruTranslation,
            }
        },
        lng: 'en',
        interpolation: {
            escapeValue: false,
        },
    });

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <RoutesApp />
    </React.StrictMode>
);
reportWebVitals();
