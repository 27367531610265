import React from 'react';
import {useTranslation} from 'react-i18next';
import './styles/styles.css';
import './styles/@styles.css';

function Pricing() {
    const { t } = useTranslation();
    return(
        <div className='pricing' id='pricing'>
            <div className='pricing-container'>
                <div className='pricing-container__title'>
                    <div className='pricing-container__title-text'>
                        <p>{t('Pricing')}</p>
                    </div>
                </div>
                <div className='pricing-container__price'>
                    <div className='pricing-container__price-container'>
                        <div className='pricing-container__price-container__title'>
                            <p>{t('Free')}</p>
                        </div>
                        <div className='pricing-container__price-container__list'>
                            <ul>
                                <li>{t('Property search')}</li>
                                <li>{t('Favorites')}</li>
                                {/*<li>{t('Opportunity')}</li>*/}
                                {/*<li>{t('Long opportunity text')}</li>*/}
                            </ul>
                        </div>
                    </div>
                    <div className='card'>
                        <div className='pricing-container__price-more'>
                            <div className='pricing-container__price-more__container'>
                                <div className='pricing-container__price-more__container-content'>
                                    <div className='pricing-container__price-more__container-content__title'>
                                        <p>{t('Broker')}</p>
                                    </div>
                                    <div className='pricing-container__price-more__container-content__list'>
                                        <ul>
                                            <li>{t('Property search')}</li>
                                            <li>{t('Favorites')}</li>
                                            {/*<li>{t('Opportunity')}</li>*/}
                                            {/*<li>{t('Long opportunity text')}</li>*/}
                                            <li><li>{t('View contacts')}</li></li>
                                            <li>{t('Generate presentations')}</li>
                                            <li>{t('Save search')}</li>
                                        </ul>
                                    </div>
                                    <div className='pricing-container__price-more__container-content__btn'>
                                        <button>{t('Try now')}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='pricing-container__price-price'>
                            <div className='pricing-container__price-price__title first'>
                                <div className='tariff'>
                                    <p>1 {t('month')}</p>
                                </div>
                                <p>
                                    <span className='dol'>$</span> <span>15</span> {t('per month')}
                                </p>
                            </div>
                            <div className='pricing-container__price-price__title second'>
                                <div className='tariff'>
                                    <p>3 {t('months')}</p>
                                </div>
                                <p>
                                    <span className='dol'>$</span> <span>12</span> {t('per month')}
                                </p>
                            </div>
                            <div className='pricing-container__price-price__title third'>
                                <div className='tariff'>
                                    <p>1 {t('year')}</p>
                                </div>
                                <p>
                                    <span className='dol'>$</span> <span>10</span> {t('per month')}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Pricing;
