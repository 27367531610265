// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.privacy {
  font-family: Raleway, sans-serif;
  background: #ffffff;
  padding: 20px;
  font-variant-numeric: lining-nums;
  font-feature-settings: 'lnum';
  -moz-font-feature-settings: 'lnum=1';
  -ms-font-feature-settings: 'lnum';
  -webkit-font-feature-settings: 'lnum';
  -o-font-feature-settings: 'lnum';
}
`, "",{"version":3,"sources":["webpack://./src/components/policy/styles.less","webpack://./src/components/policy/styles.css"],"names":[],"mappings":"AAAA;EACE,gCAAA;EACA,mBAAA;EACA,aAAA;EACA,iCAAA;EACA,6BAAA;EACA,oCAAA;EACA,iCAAA;EACA,qCAAA;EACA,gCAAA;ACCF","sourcesContent":[".privacy{\n  font-family: Raleway, sans-serif;\n  background: #ffffff;\n  padding: 20px;\n  font-variant-numeric: lining-nums;\n  font-feature-settings: 'lnum';\n  -moz-font-feature-settings: 'lnum=1';\n  -ms-font-feature-settings: 'lnum';\n  -webkit-font-feature-settings: 'lnum';\n  -o-font-feature-settings: 'lnum';\n}\n",".privacy {\n  font-family: Raleway, sans-serif;\n  background: #ffffff;\n  padding: 20px;\n  font-variant-numeric: lining-nums;\n  font-feature-settings: 'lnum';\n  -moz-font-feature-settings: 'lnum=1';\n  -ms-font-feature-settings: 'lnum';\n  -webkit-font-feature-settings: 'lnum';\n  -o-font-feature-settings: 'lnum';\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
