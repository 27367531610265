import React, { useRef, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Signin from "../signin/Signin";
import Logo from '../../assets/image/icons/logo.svg';
import UAE from '../../assets/image/images/uae.svg';
import Down from '../../assets/image/icons/down.svg';
import '../main/styles/styles.css';
import '../main/styles/@styles.css';
import ReactDOM from "react-dom";

function Main() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isLangOpen, setIsLangOpen] = useState(false);
    const [isLanguageOpen, setIsLanguageOpen] = useState(false);
    const [isSignupOpen, setIsSignupOpen] = useState(false);
    const [currentLanguage, setCurrentLanguage] = useState(() => {
        const storedLanguage = localStorage.getItem('selectedLanguage') || 'en';
        console.log('Stored Language:', storedLanguage);
        return storedLanguage;
    });
    const { t, i18n } = useTranslation();

    const langButtonRef = useRef(null);
    const dropdownRef = useRef(null);

    const closeMenu = (event) => {
        if (event && event.target) {
            const isClickInsideMenu = (
                event.target.tagName === 'A' ||
                event.target.tagName === 'P' ||
                event.target.tagName === 'SPAN'
            );
            if (isClickInsideMenu) {
                setIsMenuOpen(false);
                document.body.style.overflow = 'auto';
            }
        }
    };
    const toggleSignup = () => {
        const newSignupState = !isSignupOpen;
        setIsSignupOpen(newSignupState);
        document.body.style.overflow = newSignupState ? 'hidden' : 'auto';
    };
    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
        if (window.innerWidth <= 767) {
            document.body.style.overflow = isMenuOpen ? 'hidden' : 'auto';
        }
    };
    const toggleLang = () => {
        setIsLangOpen(!isLangOpen);
        setIsLanguageOpen(false);
    };
    const toggleLanguage = () => {
        setIsLanguageOpen(!isLanguageOpen);
        setIsLangOpen(false);
    };
    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
        setCurrentLanguage(lng);
        localStorage.setItem('selectedLanguage', lng);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleDocumentClick = (event) => {
        if (
            (isLangOpen || isLanguageOpen) &&
            !langButtonRef.current.contains(event.target) &&
            !dropdownRef.current.contains(event.target)
        ) {
            setIsLangOpen(false);
            setIsLanguageOpen(false);
        }
    };

    useEffect(() => {
        changeLanguage(currentLanguage);
    }, []);

    useEffect(() => {
        i18n.changeLanguage(currentLanguage);
    }, [currentLanguage, i18n]);

    useEffect(() => {
        document.addEventListener('click', handleDocumentClick);
        return () => {
            document.removeEventListener('click', handleDocumentClick);
        };
    }, [handleDocumentClick, isLangOpen, isLanguageOpen]);

    useEffect(() => {
        if (isSignupOpen) {
            const signinContainer = document.createElement('div');
            signinContainer.id = 'signin-container';
            document.body.insertBefore(signinContainer, document.body.firstChild);

            ReactDOM.render(<Signin onClose={toggleSignup} />, signinContainer);
        }

        return () => {
            const signinContainer = document.getElementById('signin-container');
            if (signinContainer) {
                document.body.removeChild(signinContainer);
            }
        };
    }, [isSignupOpen]);

    const handleLoginClick = () => {
        const authToken = document.cookie.split(';').find(cookie => cookie.trim().startsWith('token='));
        if (authToken) {
            // window.location.href = 'http://192.168.0.222:3000';
            window.location.href = process.env.REACT_APP_FRONTEND_URL;
        } else {
            toggleSignup();
        }
    };

    return (
        <>
            {isSignupOpen && <Signin onClose={toggleSignup}/>}
            <div className={`main ${isMenuOpen ? 'menu-open' : ''}`}>
                <div className={`main-header ${isMenuOpen ? 'menu-open' : ''}`}>
                    <div className='main-header__logo'>
                        <img src={Logo} alt=''/>
                        <span>connect</span>
                    </div>
                    <div className='main-header__lang' ref={dropdownRef}>
                        <button type='button' onClick={toggleLanguage} ref={langButtonRef}>{t('currentLanguage')}
                            <img src={Down} alt='Down' className={isLanguageOpen ? 'rotate-lng' : ''}/>
                        </button>
                        {isLanguageOpen && (
                            <div className='main-header__lang-dropdown' >
                                {currentLanguage !== 'en' && (
                                    <div className='main-header__lang-dropdown__item item1' onClick={() => changeLanguage('en')}>
                                        <span>{t('En')}</span>
                                    </div>
                                )}
                                {currentLanguage !== 'ar' && (
                                    <div className='main-header__lang-dropdown__item item2' onClick={() => changeLanguage('ar')}>
                                        <span>{t('عر')}</span>
                                    </div>
                                )}
                                {currentLanguage !== 'ru' && (
                                    <div className='main-header__lang-dropdown__item item3' onClick={() => changeLanguage('ru')}>
                                        <span>{t('Ру')}</span>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                    <div className={`main-header__links ${isMenuOpen ? 'menu-open' : ''}`}>
                        <a href='#about' onClick={() => { toggleMenu(); closeMenu(); }}>{t('About')}</a>
                        <a href='#advantages' onClick={() => { toggleMenu(); closeMenu(); }}>{t('Advantages')}</a>
                        <a href='#pricing' onClick={() => { toggleMenu(); closeMenu(); }}>{t('Pricing')}</a>
                        <div className='main-header__btns-burger'>
                            <div className='main-header__btns-burger-started' onClick={toggleSignup}>
                                <button>{t('Get started')}</button>
                            </div>
                        </div>
                        <div className='main-header__links-lang'>
                            <p onClick={toggleLang} ref={langButtonRef}>{t('currentLanguage')}
                                <img src={Down} alt='Down' className={isLangOpen ? 'rotate-lng' : ''} />
                            </p>
                            {isLangOpen && (
                                <div className='main-header__links-lang__dropdown' ref={dropdownRef}>
                                    {currentLanguage !== 'en' && (
                                        <div className='main-header__links-lang__dropdown-item item1' onClick={() => changeLanguage('en')}>
                                            <span>{t('En')}</span>
                                        </div>
                                    )}
                                    {currentLanguage !== 'ar' && (
                                        <div className='main-header__links-lang__dropdown-item item2' onClick={() => changeLanguage('ar')}>
                                            <span>{t('عر')}</span>
                                        </div>
                                    )}
                                    {currentLanguage !== 'ru' && (
                                        <div className='main-header__links-lang__dropdown-item item3' onClick={() => changeLanguage('ru')}>
                                            <span>{t('Ру')}</span>
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                    <div className='main-header__btns'>
                        <div className='main-header__btns-signup' onClick={handleLoginClick}>
                            <button>{t('Log In')}</button>
                        </div>
                        <div className='main-header__btns-started' onClick={toggleSignup}>
                            <button>{t('Get started')}</button>
                        </div>
                    </div>
                    <div className='burger-menu' onClick={toggleMenu}>
                        {isMenuOpen ? (
                            <>
                                <div className='burger-menu__line-cross1'></div>
                                <div className='burger-menu__line-cross2'></div>
                            </>
                        ) : (
                            <>
                                <div className='burger-menu__line'></div>
                                <div className='burger-menu__line'></div>
                                <div className='burger-menu__line'></div>
                            </>
                        )}
                    </div>
                </div>
                <div className={`menu-overlay ${isMenuOpen ? 'menu-open' : ''}`} onClick={event => closeMenu(event)}></div>
                <div className='main-content'>
                    <div className='main-content__text'>
                        <h1>
                            {t('Sale Apartments')}<br/>
                            {t('in')} <img src={UAE} alt='' /> <span>{t('UAE')}</span>
                        </h1>
                        <p>{t('Only real and relevant offers')}</p>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Main;
